import 'typeface-arvo'
import 'typeface-oswald'
import 'typeface-aleo'
import './Layout.css'
import React from 'react'
import Helmet from 'react-helmet'
import { graphql, StaticQuery } from 'gatsby'
import withRoot from '../mui/withRoot'
import { withStyles, Grid } from '@material-ui/core'
import colors from '../utils/colors'

const Layout = props => {
  const { children } = props
  const content = data => {
    return (
      <>
        <Helmet>
          <script
            async
            src="https://www.googletagmanager.com/gtag/js?id=UA-123338723-1"
          />
          <script>
            {`
                window.dataLayer = window.dataLayer || [];
                function gtag(){dataLayer.push(arguments);}
                gtag('js', new Date());
                gtag('config', 'UA-123338723-1');
            `}
          </script>

          <script>
            {`
              (function(i,s,o,g,r,a,m){i['GoogleAnalyticsObject']=r;i[r]=i[r]||function(){
              (i[r].q=i[r].q||[]).push(arguments)},i[r].l=1*new Date();a=s.createElement(o),
              m=s.getElementsByTagName(o)[0];a.async=1;a.src=g;m.parentNode.insertBefore(a,m)
              })(window,document,'script','https://www.google-analytics.com/analytics.js','ga');

              ga('create', 'UA-123338723-1', 'auto');
              ga('send', 'pageview');
            `}
          </script>
          <title>{data.site.siteMetadata.title}</title>
          <meta
            name="Description"
            content={data.site.siteMetadata.description}
          />
        </Helmet>
        <div style={{ padding: '0 16px' }}>{children}</div>
        <Grid
          container
          style={{
            background: colors.primary.base,
            height: '70px',
          }}
          alignItems="center"
        >
          <Grid item xs={12}>
            <p
              style={{
                textAlign: 'center',
                padding: '0 16px 0 16px',
                fontFamily: 'Aleo, slab-serif',
                color: 'white',
              }}
            >
              Copyright © {new Date().getFullYear()} Roots & Rove
            </p>
          </Grid>
        </Grid>
      </>
    )
  }
  return (
    <StaticQuery
      query={graphql`
        query LayoutQuery {
          site {
            siteMetadata {
              title
              siteUrl
              description
              keywords
            }
          }
        }
      `}
      render={content}
    />
  )
}

export default withRoot(withStyles({})(Layout))
