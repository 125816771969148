import React from 'react'
import { withStyles } from '@material-ui/core'
import NavBar from '../components/NavBar'

import { graphql } from 'gatsby'
import Layout from '../components/Layout'
import colors from '../utils/colors'
import { jpg } from '../utils/strings'

const styles = theme => ({
  header: {
    fontFamily: 'Oswald, sans',
    fontSize: '100px',
    fontWeight: 'lighter',
    margin: '0',
  },
  root: {
    padding: '24px',
    maxWidth: '1000px',
    margin: 'auto',
    fontSize: '24px',
    fontFamily: 'Aleo, slab-serif',
  },
  sideImage1: {
    backgroundSize: 'contain',
    width: '50%',
    boxShadow: `-24px -24px 0px ${colors.primary.base}`,
    margin: '48px',
    float: 'right',
    [theme.breakpoints.down('sm')]: {
      width: '90%',
      float: 'none',
    },
  },
  sideImage2: {
    backgroundSize: 'contain',
    width: '50%',
    boxShadow: `24px 24px 0px ${colors.secondary.base}`,
    margin: '48px',
    float: 'right',
    [theme.breakpoints.down('sm')]: {
      width: '90%',
      float: 'none',
    },
  },
  signature: {
    fontWeight: 'bold',
  },
})

const Cuisine = props => {
  const { classes, data } = props
  const images = data.images.edges
  return (
    <Layout>
      <NavBar />
      <div className={classes.root}>
        <h3 className={classes.header}>THE CHEF</h3>
        <p>Hi! - Caitlin here.</p>
        <img
          src={images[0].node.file.url}
          className={classes.sideImage1}
          alt="the chef 1"
        />
        <p>
          I’m a self-taught home cook, organic recipe creator and local
          enthusiast. Most know me as jack of all trades, but there’s probably
          nothing I love more than kicking it in the kitchen. No recipes, no
          rules, just me and the potential to make an unforgettable meal. I
          can’t wait to connect with you and help your culinary vision blossom!
        </p>
        <img
          src={images[1].node.file.url + jpg(1280, 720)}
          className={classes.sideImage2}
          alt="the chef 2"
        />
        <p>
          Being based in Charlottesville, Virginia, I have an abundance of
          natural beauty and naturally amazing ingredients at my fingertips. I’m
          deeply inspired by the local growing and small business culture in the
          Shenandoah Valley, and I love bringing the local yield to life in my
          dishes. My passion for taking kick-ass local variety and turning it
          into something even more delicious has grown into a collection of
          organic, wholesome recipes and ideas that make eating like a king seem
          simple. It’s also taken me out of my own kitchen and into to the homes
          of others who are looking for a break or a taste of something new.
        </p>
        <p>
          Although I am a one-woman show in the kitchen, food is not a solo
          endeavor. A meal is always meant to be shared - with your family, with
          your friends, or even with a stranger. For me, there is no greater
          gratification than serving the ones I love with fresh, wholesome food
          grown by our hardworking neighbors and friends. I want to share the
          power of a shared meal with you and your guests through this exclusive
          private dining experience. From the wine pairings to place settings
          and decor, I’ll help your unique vision come to life.
        </p>
        <p>
          I can’t wait to connect with you and help your culinary vision
          blossom!
        </p>
        <p className={classes.signature}>
          <i>- CW</i>
        </p>
      </div>
    </Layout>
  )
}

export const query = graphql`
  query chef {
    images: allContentfulAsset(
      filter: { title: { in: ["the-chef-top", "the-chef-bottom"] } }
      sort: { order: DESC, fields: title }
    ) {
      edges {
        node {
          title
          file {
            url
          }
        }
      }
    }
  }
`

export default withStyles(styles)(Cuisine)
