export default {
  primary: {
    base: '#ddc333',
    light: '#fff567',
    dark: '#a89300',
  },
  secondary: {
    base: '#badbc9',
    light: '#edffcc',
    dark: '#8aa998',
  },
}
