import React from 'react'
import { Link, graphql, StaticQuery } from 'gatsby'
import { withStyles, Grid } from '@material-ui/core'
import { OutboundLink } from 'gatsby-plugin-google-analytics'

const styles = () => ({
  navroot: {
    padding: 30,
    color: 'black',
    lineHeight: 1,
    zIndex: 1000,
    pointerEvents: 'none',
    textAlign: 'right',
  },
  link: {
    fontFamily: 'Aleo, slab-serif',
    textAlign: 'right',
    textDecoration: 'none',
    color: 'black',
    fontSize: '20px',
    pointerEvents: 'all',
    whiteSpace: 'nowrap',
  },
  menuLink: {
    fontFamily: 'Aleo, slab-serif',
    textAlign: 'center',
    color: 'white',
    pointerEvents: 'all',
  },
  popover: {
    backgroundColor: 'rgba(50, 50, 50, 0.7)',
  },
})

class NavBar extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      showAbout: false,
    }
  }

  onMouseOverAbout = e => {
    this.setState({ showAbout: e.target })
  }

  onClickAbout = e => {
    this.setState({ showAbout: this.state.showAbout ? null : e.target })
  }

  onMouseLeaveAbout = () => {
    this.setState({ showAbout: null })
  }

  render() {
    const { classes } = this.props
    const LinkGridItem = props => {
      return (
        <Grid item>
          <div>{props.children}</div>
        </Grid>
      )
    }
    return (
      <StaticQuery
        query={graphql`
          query CategoriesNav {
            allContentfulCategory(sort: { order: ASC, fields: index }) {
              edges {
                node {
                  displayText
                  url
                }
              }
            }
          }
        `}
        render={data => (
          <Grid
            container
            className={classes.navroot}
            justify="flex-end"
            spacing={24}
          >
            <LinkGridItem>
              <Link className={classes.link} to="/">
                Home
              </Link>
            </LinkGridItem>
            <LinkGridItem>
              <Link className={classes.link} to="/blog">
                The Blog
              </Link>
            </LinkGridItem>
            <LinkGridItem>
              <Link className={classes.link} to="/the-chef">
                The Chef
              </Link>
            </LinkGridItem>
            <LinkGridItem>
              <Link className={classes.link} to="/the-cuisine">
                The Cuisine
              </Link>
            </LinkGridItem>
            <LinkGridItem>
              <OutboundLink
                className={classes.link}
                href="https://www.instagram.com/rootsandrove"
                target="_blank"
                rel="noopener"
              >
                Instagram
              </OutboundLink>
            </LinkGridItem>
          </Grid>
        )}
      />
    )
  }
}

export default withStyles(styles)(NavBar)
