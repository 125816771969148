export const jpg = (w = 1920, h = 1080) => {
  return `?fm=jpg&fl=progressive&w=${w}&h=${h}`
}

export const insta = 'https://www.instagram.com/rootsandrove'

export default {
  jpg,
  insta,
}
